<template>
  <div class="project-from-stack">
    <CyDetails
      can-cancel
      hide-delete
      :loading="false"
      :on-cancel="() => { $router.push({ name: 'projects' }) }"
      :can-save="formIsValid && !_.isEmpty(selectedStack)"
      :on-save="createProject"
      :saving="creating"
      :save-btn-text="$t('createProject')">
      <template #details_formFullWidth>
        <div
          v-if="!selectedStack"
          class="stack-selection space-x-6">
          <div class="stack-selection__text">
            <h3
              class="mb-4"
              v-text="$t('selectStackTitle')"/>
            <p v-text="$t('selectStackText')"/>
          </div>
          <div class="stack-list__wrapper">
            <CyWizardServiceCatalog
              v-model="selectedStack"
              :has-create-stack-btn-visible="false"
              hide-hidden-filter/>
          </div>
        </div>
        <v-container
          v-else
          fluid>
          <v-row
            align="start"
            no-gutters>
            <v-col>
              <h3 v-text="$t('Stack')"/>
            </v-col>
            <v-col
              cols="8"
              lg="5">
              <p
                class="subtitle grey--text text--darken-1 mb-2"
                v-text="$t('Stack')"/>
              <CyWizardServiceCard
                aria-label="Selected stack"
                hide-more-btn
                :service="selectedStack"/>
              <button
                class="change-stack--btn mt-2"
                @click="selectedStack = null"
                v-text="$t('changeStack')"/>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.lgAndUp"/>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-divider class="my-8"/>
            </v-col>
            <v-col
              cols="8"
              lg="5">
              <v-divider class="my-8"/>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.lgAndUp"/>
          </v-row>
          <v-row
            align="start"
            no-gutters>
            <v-col>
              <h3 v-text="$t('general')"/>
            </v-col>
            <v-col
              cols="8"
              lg="5">
              <CyFormsProject
                @input="projectConfig = $event"
                @is-valid="formIsValid = $event"/>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.lgAndUp"/>
          </v-row>
        </v-container>
        <portal
          v-if="previewedStack"
          to="side-panel">
          <div class="side-panel-backdrop"/>
          <v-slide-x-reverse-transition>
            <v-card
              v-click-outside="{
                handler: closePreview,
                include: getClickOutsideExceptions,
              }"
              aria-label="Stack preview"
              role="region"
              class="side-panel">
              <CyStackPreview
                :stack="previewedStack"
                @close="closePreview"
                @select="selectFromPanel"/>
            </v-card>
          </v-slide-x-reverse-transition>
        </portal>
      </template>
    </CyDetails>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CyDetails from '@/components/details.vue'
import CyFormsProject from '@/components/forms/project.vue'
import CyStackPreview from '@/components/stack-preview.vue'
import CyWizardServiceCard from '@/components/wizard/service-card.vue'
import CyWizardServiceCatalog from '@/components/wizard/service-catalog.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageProjectFromStack',
  components: {
    CyWizardServiceCard,
    CyFormsProject,
    CyDetails,
    CyStackPreview,
    CyWizardServiceCatalog,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('title'), [
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('title'),
      description: {
        text: this.$t('gettingStartedText'),
        link: $docLinks.project.deploy,
      },
    }
  },
  data: () => ({
    selectedStack: null,
    projectConfig: null,
    formIsValid: false,
    creating: false,
    providerOptions: [],
    previewedStack: null,
  }),
  computed: {
    ...mapState('organization', {
      stacks: (state) => state.available.stacks,
    }),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfo',
    ]),
  },
  async created () {
    const { selectedStackRef } = this.$route.query
    if (selectedStackRef) {
      this.selectedStack = this.stacks.find((stack) => stack.ref === selectedStackRef)
      this.$router.replace({ query: {} })
    }
    this.setProviderOptions()
  },
  methods: {
    ...mapActions('organization/project', [
      'CREATE_PROJECT',
    ]),
    async createProject () {
      const { formIsValid, selectedStack, projectConfig } = this

      if (!formIsValid) return

      this.creating = true
      const noErrorsOccured = await this.CREATE_PROJECT(_.$snakeCaseKeys({ ...projectConfig, serviceCatalogRef: selectedStack.ref }))
      if (noErrorsOccured) this.$router.push({ name: 'projectEmpty', params: { projectCanonical: projectConfig.canonical } })
      this.creating = false
    },
    setProviderOptions () {
      const providers = _(this.stacks)
        .flatMap('keywords')
        .filter((keyword) => keyword.startsWith('provider:'))
        .map((keyword) => keyword.replace('provider:', ''))
        .uniq()
        .map((provider) => this.getProviderExtraInfo[provider])
        .value()
      const anyOption = {
        displayName: this.$t('any'),
        providerCanonical: null,
      }
      this.providerOptions = [anyOption, ...providers]
    },
    getClickOutsideExceptions () {
      const selectors = [
        '.main-nav a',
        '.main-nav button',
        '.dev-locale-switcher__options',
        '.dev-layer',
        '.v-menu__content',
      ]
      return Array.from(document.querySelectorAll(selectors.join(', ')))
    },
    closePreview () {
      this.previewedStack = null
    },
    selectFromPanel () {
      this.selectedStack = this.previewedStack
      this.closePreview()
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Create new @:project',
        any: 'Any',
        changeStack: 'Change stack',
        createProject: 'Create project',
        gettingStartedText: 'A project is a set of environments built around a stack. Follow the Getting started guide to get familiar with projects.',
        selectStackText: 'Select the stack to use in this project, all environments in this project will reference this stack. The stack cannot be changed once the project is created.',
        selectStackTitle: 'Select stack',
      },
      es: {
        title: 'Crear nuevo @:project',
        any: 'Cualquiera',
        changeStack: 'Cambiar stack',
        createProject: 'Crear un proyecto',
        gettingStartedText: 'Un proyecto es un conjunto de entornos construidos alrededor de un stack. Sigue la guía de inicio para familiarizarte con los proyectos.',
        selectStackText: 'Selecciona el stack a usar en este proyecto, todos los entornos de este proyecto harán referencia a este stack. El stack no se puede cambiar una vez creado el proyecto.',
        selectStackTitle: 'Seleccionar un stack',
      },
      fr: {
        title: 'Créer un nouveau @:project',
        any: 'Tous',
        changeStack: 'Changer de stack',
        createProject: 'Créer un projet',
        gettingStartedText: `Un projet est un ensemble d'environnements construits autour d'une stack. Suivez le guide de démarrage pour vous familiariser avec les projets.`,
        selectStackText: `Sélectionnez la stack à utiliser dans ce projet, tous les environnements de ce projet feront référence à cette stack. La stack ne peut pas être modifiée une fois le projet créé.`,
        selectStackTitle: 'Sélectionner une stack',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.project-from-stack {
  .stack-list__wrapper {
    flex-grow: 1;
    padding: 0;
    padding-right: 8px;

    .stack-filters {
      display: flex;

      &__type,
      &__provider {
        width: 200px;
        max-width: 200px;
      }
    }
  }

  .stack-selection {
    display: flex;
    max-width: 100%;
    padding-right: 8px;

    &__text {
      flex-basis: 350px;
      flex-shrink: 0;
    }

    &__list {
      flex-grow: 1;
      padding: 0;
      list-style-type: none;
    }

    &__loading {
      display: flex;
      flex-direction: column;
      width: 100%;

      .sk-template {
        width: 100%;
      }
    }
  }

  .change-stack--btn {
    color: cy-get-color("secondary");
    font-weight: $font-weight-bold;
    text-decoration: none;
    cursor: pointer;
  }
}

.v-list-item__avatar {
  .svg-inline--fa {
    width: 24px;
    height: 24px;
  }
}

.side-panel {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 830px;
  height: calc(100% - #{$offset} * 2);

  &-backdrop {
    position: fixed;
    z-index: 108;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 0.5);
  }
}
</style>
